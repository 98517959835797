<template>
    <base-section id="features">
        <v-responsive class="mx-auto" max-width="1350">
            <v-container fluid>
                <v-row>
                    <v-col
                        v-for="card in cards"
                        :key="card.title"
                        cols="12"
                        sm="4"
                        md="4"
                    >
                        <base-info-card align="center" dark v-bind="card" />
                    </v-col>
                    <v-col cols="2">
                        <p class="john" />
                    </v-col>
                </v-row>
            </v-container>
        </v-responsive>
    </base-section>
</template>

<script>
export default {
    name: 'SectionFeatures',

    data: () => ({
        cards: [
            {
                icon: 'mdi-phone',
                title: 'Contact Us',
                text: 'Phone Number, Twitter, Facebook, Instagram, Email',
            },

            {
                icon: 'mdi-bank',
                title: 'Confirm Product',
                text:
                    'When your Chemical arrives you will need to correct-check it to confirm if it is exactly what you ordered ',
            },
            {
                icon: 'mdi-currency-ngn',
                title: 'Make Payment',
                text: 'Kindly make payment to the product you bought',
            },
        ],
    }),
};
</script>

<style>
.john {
    color: gray !important;
    font-size: 5px !important;
}
#features {
    background-color: #0b2571;
}
</style>
